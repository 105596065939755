export const GET_USERS = 'GET_USERS'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const LOG_USER_OUT = 'LOG_USER_OUT'
export const LOGIN_USER = 'LOGIN_USER'
export const CREATE_USER = 'CREATE_USER'
export const GET_ONLINE_USERS = 'GET_ONLINE_USERS'


export const GET_LEADER_BOARD = 'GET_LEADER_BOARD'


export const GET_CHALLENGES = 'GET_CHALLENGES'
export const GET_CHALLENGE_DETAILS = 'GET_CHALLENGE_DETAILS'
export const GET_CHALLENGE_RESULT = 'GET_CHALLENGE_RESULT'


export const GET_CHALLENGE_INVITES = 'GET_CHALLENGE_INVITES'

export const GET_CHALLENGE_PARTICIPANTS = 'GET_CHALLENGE_PARTICIPANTS'


export const SET_LOADING = 'SET_LOADING'


export const GET_CHALLENGE_SUMMARY = 'GET_CHALLENGE_SUMMARY'