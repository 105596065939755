import React, { Fragment } from 'react'



const History = () => {


    return <Fragment>
        <h5>History</h5>
        <div className="history my-2">
            <div className="d-flex justify-content-between">
                <h5>Implement various sorting algorithms</h5>
                <span style={{ color: '#28A745'}}>Won</span>
            </div>
            <p>Implement the sorting algorithm to sort an array of integers in ascending order. Implement bubble sort algorithm to sort integers in ascending order</p>
            <div className="d-flex justify-content-between">
                <div>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <span className="additional-text">+ 2100 more</span>
                </div>
                <div style={{ fontSize: '14px' }}>Position: 123</div>
            </div>
        </div>

        <div className="history my-2">
            <div className="d-flex justify-content-between">
                <h5>Implement various sorting algorithms</h5>
                <span style={{ color: '#28A745'}}>Won</span>
            </div>
            <p>Implement the sorting algorithm to sort an array of integers in ascending order. Implement bubble sort algorithm to sort integers in ascending order</p>
            <div className="d-flex justify-content-between">
                <div>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <span className="additional-text">+ 2100 more</span>
                </div>
                <div style={{ fontSize: '14px' }}>Position: 123</div>
            </div>
        </div>

        <div className="history my-2">
            <div className="d-flex justify-content-between">
                <h5>Implement various sorting algorithms</h5>
                <span style={{ color: '#28A745'}}>Won</span>
            </div>
            <p>Implement the sorting algorithm to sort an array of integers in ascending order. Implement bubble sort algorithm to sort integers in ascending order</p>
            <div className="d-flex justify-content-between">
                <div>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <i className="mdi mdi-account-multiple"></i>
                    <span className="additional-text">+ 2100 more</span>
                </div>
                <div style={{ fontSize: '14px' }}>Position: 123</div>
            </div>
        </div>
    </Fragment>
}

export default History