import React from 'react'



const SunShine = () => {





    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path d="M12.3332 38.9665L16.9999 34.1665C15.3332 33.8332 13.7999 33.1665 12.3999 32.2332L12.3332 38.9665ZM27.6665 2.0332L22.9999 6.8332C24.6665 7.16654 26.1999 7.8332 27.5999 8.76654L27.6665 2.0332ZM1.5332 28.1665L8.26654 28.0999C7.3332 26.6999 6.66654 25.1665 6.3332 23.4999L1.5332 28.1665ZM38.4665 12.8332L31.7332 12.8999C32.5999 14.2999 33.2665 15.8332 33.6665 17.4999L38.4665 12.8332ZM1.5332 12.8332L6.3332 17.4999C6.66654 15.8332 7.3332 14.2999 8.26654 12.8999L1.5332 12.8332ZM38.4665 28.1665L33.6665 23.4999C33.3332 25.1665 32.6665 26.6999 31.7332 28.0999L38.4665 28.1665ZM12.3332 2.0332L12.3999 8.76654C13.7999 7.89987 15.3332 7.2332 16.9999 6.8332L12.3332 2.0332ZM27.6665 38.9665L27.5999 32.2332C26.1999 33.1665 24.6665 33.8332 22.9999 34.1665L27.6665 38.9665Z" fill="#C0C0C0"/>
    <path d="M8.53333 28.5L5.86667 34.7L12.0667 32.0333C10.6667 31.0333 9.46667 29.8333 8.53333 28.5ZM31.4667 12.5L34.1333 6.3L28 8.96667C29.3333 9.96667 30.5333 11.1667 31.4667 12.5ZM6.26667 18.0333L0 20.5L6.26667 22.9667C6.06667 22.1667 6 21.3667 6 20.5C6 19.6333 6.06667 18.8333 6.26667 18.0333ZM33.7333 22.9667L40 20.5L33.7333 18.0333C33.8667 18.8333 34 19.7 34 20.5C34 21.3667 33.9333 22.1667 33.7333 22.9667ZM12 9.03333L5.8 6.36667L8.46667 12.5667C9.46667 11.1667 10.6667 9.96667 12 9.03333ZM28 31.9667L34.2 34.6333L31.5333 28.4333C30.5333 29.8333 29.3333 31.0333 28 31.9667ZM22.4667 6.76667L20 0.5L17.5333 6.76667C18.3333 6.63333 19.2 6.5 20 6.5C20.8 6.5 21.6667 6.56667 22.4667 6.76667ZM17.5333 34.2333L20 40.5L22.4667 34.2333C21.6667 34.4333 20.8667 34.5 20 34.5C19.1333 34.5 18.3333 34.4333 17.5333 34.2333Z" fill="#EBD40B"/>
    <path d="M20.0002 33.1663C26.9958 33.1663 32.6668 27.4953 32.6668 20.4997C32.6668 13.5041 26.9958 7.83301 20.0002 7.83301C13.0046 7.83301 7.3335 13.5041 7.3335 20.4997C7.3335 27.4953 13.0046 33.1663 20.0002 33.1663Z" fill="#EBD40B"/>
    </svg>
}


export default SunShine