import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { submitChallengeResponse } from '../../redux/actions/challengeActions';


const MultipleChoiceQuestionDetail = ({ challengeId, questions, setShowSuccessModal, loading, userResponse, setUserResponses, setStartChallenge }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(Array(questions?.length).fill([]));

    const dispatch = useDispatch()


    

    const handleOptionChange = (questionIndex, optionIndex) => {
      
        const newAnswers = [...answers];
        console.log('new answers ', newAnswers, 'questionIndex ', questionIndex)
        if (!newAnswers || !Array.isArray(newAnswers[questionIndex])) return;
        let updatedAnswer = [...newAnswers[questionIndex]];
        const index = updatedAnswer.indexOf(optionIndex);
        if (index === -1) {
            if (hasMultipleAnswers()) updatedAnswer.push(optionIndex);
            else updatedAnswer = [optionIndex]
        } else {
            updatedAnswer.splice(index, 1);
        }
        newAnswers[questionIndex] = updatedAnswer;
        setAnswers(newAnswers);
    };


    const handleOptionSelect = (questionId, optionId) => {
        setUserResponses(prevResponses => {
            let updatedResponses = { ...prevResponses };
    
            if (!updatedResponses[questionId]) {
                updatedResponses[questionId] = [optionId];
            } else {
                const optionIndex = updatedResponses[questionId].indexOf(optionId);
                if (optionIndex === -1) {
                    if (hasMultipleAnswers()) updatedResponses[questionId].push(optionId);
                    else updatedResponses[questionId] = [optionId]
                } else {
                    updatedResponses[questionId].splice(optionIndex, 1);
                }
            }
            return updatedResponses;
        });
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };


    const submitQuiz = () => {
        //submit answers
        console.log('answers ', answers)
        const request = {
            challengeId,
            userResponse
        }
        dispatch(submitChallengeResponse(request, () => {
            setShowSuccessModal(true);
            setStartChallenge(false)
        }))
          
    }

    const hasMultipleAnswers = () => {
        return !!questions[currentQuestion].multipleChoiceQuestion.hasMultipleAnswers
    }


    const renderQuestion = () => {
        
    }

    console.log('questions ', questions, 'answers ', answers)
    return (!questions || questions?.length === 0) ? (<h6 className='mt-5'>Oops! There are no questions in this challenge. Kindly contact Admin...</h6>) : (<Fragment>
            <div className="row">
                <div className="question-container col-lg-12 text-left mt-5 ml-0 pl-0">
                    <div className="question-header d-flex justify-content-between">
                        <h5 className="question-title">{`Question ${currentQuestion + 1
                            }`}</h5>
                        <span className="question-range">{`${currentQuestion + 1} of ${questions?.length}`}</span>
                    </div>
                    <div className="question-body py-3">
                        <h6 className="question-text">
                            {" "}
                            { questions && questions[currentQuestion].title}
                        </h6>
                        {
                            questions[currentQuestion].imageUrl && (<div className='image-container' style={{ height: '250px', width: '100%'}}>
                                <img src={questions[currentQuestion]?.imageUrl} alt='Quiz Image' style={{ height: '100%', width: '100%', objectFit: 'cover', imageRendering: 'auto' }} />
                            </div>)
                        }
                        
                        <ol type="a" className="mt-3 pl-1">
                        {questions && questions[currentQuestion]?.multipleChoiceQuestion?.options.map((option, index) => (
                            <Fragment key={index}>
                                <div
                                    className="option-container d-flex align-items-center"
                                    key={option.id}
                                >
                                    <input
                                        type={`${!!questions[currentQuestion].multipleChoiceQuestion.hasMultipleAnswers ? 'checkbox' : 'radio'}`}
                                        className="form-contro"
                                        id={`option${option.id}`}
                                        name="option"
                                        checked={answers[currentQuestion]?.includes(option.id)}
                                        onChange={() => {
                                            handleOptionChange(currentQuestion, option?.id)
                                            handleOptionSelect(questions[currentQuestion]?.id, option?.id)
                                        }}
                                    />
                                    <label
                                        className="pointer ml-2 mb-0 pb-0"
                                        htmlFor={`option${option.id}`}
                                    >
                                        {option.value}
                                    </label>
                                </div>
                            </Fragment>
                        ))}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-md-5 col-lg-7 controls d-flex justify-content-between flex-row">
                    <div className="d-flex align-items-center">
                        <div
                            className={`pointer f-14 ${currentQuestion === 0 ? 'text-grey': ''}`}
                            onClick={handlePrevious}
                            disabled={currentQuestion === 0}
                        >
                            Previous
                        </div>
                        <div
                            className={`ml-7 f-14 pointer ${currentQuestion === questions?.length - 1 ? 'text-grey': ''}`}
                            onClick={handleNext}
                            disabled={currentQuestion === questions.length - 1}
                        >
                            Next
                        </div> 
                    </div>
                    {
                        currentQuestion === questions.length - 1 && (<button
                            type="submit"
                            className="btn btn-lg btn-block custom-btn-primary"
                            style={{ fontSize: '16px', width: '200px'}}
                            onClick={submitQuiz}
                            >
                                { loading && (<span className="spinner-border spinner-border-sm mr12" id="login-btn-loader" role="status" aria-hidden="true"></span>) }
                                Submit
                            </button>)
                    }
                </div>
               
            </div>

        </Fragment>)

}


const mapStateToProps = ({ loading }) => {
    return ({
        loading
    })
}
export default connect(mapStateToProps, { submitChallengeResponse  })(MultipleChoiceQuestionDetail)


