import React from 'react'
import 'react-notifications/lib/notifications.css'
import {  NotificationContainer } from 'react-notifications'

const Notification = () => {
    return <NotificationContainer />  
}



export default Notification